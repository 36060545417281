import "./App.scss";
import useKrpano from "react-krpano-hooks";
import React, { useState } from "react";

function App() {
  const [active, setActive] = useState(1);
  const [currentScene, setCurrentScene] = useState("");

  const { containerRef, callKrpano } = useKrpano({
    globalFunctions: {
      logNewScene: (scene) => {
        // console.log("New scene: ", scene);
        setCurrentScene(scene)
        if(scene === "scene_54_59-bancong") {
          setActive(1);
        }
      },
    },
  });

  return (
    <div className="App">
      <div ref={containerRef} style={{ width: "100%", height: "100%" }} />

      {(currentScene === "scene_54_59-bancong" ||
        currentScene === "scene_54_59-bancong-40" ||
        currentScene === "scene_54_59-bancong-20") && (
        <div className="house-living_room">
          <div
            className={active === 1 ? "btn active" : "btn"}
            onClick={() => {
              setActive(1);
              callKrpano(
                `loadscene(scene_54_59-Bancong, null, MERGE|KEEPVIEW, BLEND(0.5));`
              );
            }}
          >
            100m
          </div>
          <div
            className={active === 2 ? "btn active" : "btn"}
            onClick={() => {
              setActive(2);
              callKrpano(
                `loadscene(scene_54_59-Bancong-40, null, MERGE|KEEPVIEW, BLEND(0.5));`
              );
            }}
          >
            40m
          </div>
          <div
            className={active === 3 ? "btn active" : "btn"}
            onClick={() => {
              setActive(3);
              callKrpano(
                `loadscene(scene_54_59-Bancong-20, null, MERGE|KEEPVIEW, BLEND(0.5));`
              );
            }}
          >
            20m
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
